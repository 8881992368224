import React from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/hero";
import StorageOwners from "../../components/storageOwners";
import About from "../../components/about";
import Footer from "../../components/Footer";
import Pricing from "../../components/pricing";
import WhatsAppIcon from "../../components/whatsappchat";

const Index = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <StorageOwners />
      <About />
      <Pricing />
      <Footer />
      <WhatsAppIcon />
    </div>
  );
};

export default Index;
