import React, { useEffect } from "react";
import Layout from "../layouts";
import { Container } from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import { faqsData } from "./data";
import { FaRegQuestionCircle } from "react-icons/fa";
import WhatsAppIcon from "../whatsappchat";

const FAQs = () => {
  return (
    <>
      <Layout>
        <Container maxW={"7xl"} mt={{ base: "6rem", md: "6rem", lg: "10rem" }}>
          <SolutionsContainer>
            <Accordion defaultIndex={[0]} allowMultiple>
              <Text my={"1rem"} fontWeight={"bold"} fontSize={"xl"} ml={4}>
                FREQUENTLY ASKED QUESTIONS (FAQs)
              </Text>

              {faqsData.map((faq, index) => (
                <AccordionItem key={faq.id}>
                  <h2>
                    <AccordionButton py="1.5rem">
                      <Flex mr={4}>
                        <FaRegQuestionCircle />
                      </Flex>
                      <Text
                        fontSize={{ base: "1rem", md: "1.2rem", lg: "1.5rem" }}
                        fontWeight={"medium"}
                        flex="1"
                        textAlign="left"
                      >
                        {faq.question}
                      </Text>
                      <AccordionIcon fontSize={"1.5rem"} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex alignItems="center">
                      <Box>
                        {Array.isArray(faq.answer) ? (
                          <ul
                            style={{
                              marginLeft: "30px",
                            }}
                          >
                            {faq.answer.map((subItem, subIndex) => (
                              <li
                                key={subIndex}
                                style={{
                                  listStyleType: "disc",
                                }}
                              >
                                {subItem}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <Text
                            fontSize={{
                              base: "0.9rem",
                              md: "1rem",
                              lg: "1.2rem",
                            }}
                            ml={8}
                          >
                            {faq.answer}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </SolutionsContainer>
          <WhatsAppIcon />
        </Container>
      </Layout>
    </>
  );
};

export default FAQs;

const SolutionsContainer = styled.div`
  background-color: #fff;
  padding: 1rem 2rem;

  @media (max-width: 700px) {
    padding: 1rem 0;

    ul {
      margin-left: 0;
    }
  }
`;
