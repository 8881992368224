import React from "react";
import FAQs from "../../components/faqs";

const FaqPage = () => {
  return (
    <div>
      <FAQs />
    </div>
  );
};

export default FaqPage;
