import Image1 from "../../assets/about/personal.jpg";
import Image2 from "../../assets/about/reactout.png";
import Image3 from "../../assets/about/contract.jpg";

export const aboutData = [
  {
    id: 1,
    title: "Personal Service",
    descritpion:
      "We began as a digital storage solution, but the demand for a more personalized experience led us to create something extraordinary. At our storage facility, we provide individual private rooms for your belongings ensuring the utmost care and security.",
    image: Image1,
  },
  {
    id: 2,
    title: "Small is Beautiful",
    descritpion:
      "We're very approachable! Call us, email us, WhatsApp us and have access at a time to suit. (Even weekends, though we are not early birds!) Whether you want to grab your golf clubs, store the Christmas decorations or find that warm winter coat.",
    image: Image2,
  },
  {
    id: 3,
    title: "Flexible Contracts",
    descritpion:
      "Are you buying a house and hoping to complete in a couple months, building a new home and been promised 12 months, or not sure where your work contract will send you in the Autumn - No problem! We have month-by-month contracts - easy and flexible.",
    image: Image3,
  },
];
