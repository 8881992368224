import Image1 from "../../assets/pricing/private.jpg";
import Image2 from "../../assets/pricing/shared.png";
import Image3 from "../../assets/pricing/box.png";

export const storageData = [
  {
    id: 1,
    image: Image1,
    type: "Private Storage",
    description:
      "Enjoy the ultimate storage experience with your own dedicated space. Our private storage units offer maximum security and privacy for your belongings. Store your items with peace of mind, knowing that they are protected in a space exclusively for you.",
    cost: "4 sqm storage space at N67,500 per month",
  },
  {
    id: 2,
    image: Image2,
    type: "Shared Storage",
    description:
      "Share storage space with others in our secure facility. Our shared storage option is perfect for those looking for cost-effective storage solutions. Benefit from a reduced price while still having a dedicated area to store your items.",
    cost: "N44,500 per month to a maximum of 10 items",
  },
  {
    id: 3,
    image: Image3,
    type: "Box Storage",
    description:
      "Need a compact storage option? Our box lockers provide a convenient and affordable solution. Store your smaller items or valuable belongings in a secure locker, giving you easy access whenever you need them.",
    cost: "At N7,950 per box per month to a maximum box dimension of 60 cm on its longest",
  },
];
