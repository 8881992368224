// import { SiPaloaltosoftware } from "react-icons/si";
// import { CgClose } from "react-icons/cg";
// import { useEffect, useRef, useState } from "react";
// import { Box, Container, Flex, Image } from "@chakra-ui/react";
// import { Link, NavLink, useLocation } from "react-router-dom";
// import { GrMenu } from "react-icons/gr";
// import Logo from "../../assets/logo-white.png";
// import { animateScroll as scroll } from "react-scroll";
// // Custom hook to handle window click event
// const useWindowClick = (callback) => {
//   useEffect(() => {
//     const handleClick = (event) => {
//       if (callback) {
//         callback();
//       }
//     };

//     // Add event listener to the window for mousedown event
//     window.addEventListener("click", handleClick);

//     return () => {
//       // Remove the event listener when the component unmounts
//       window.removeEventListener("click", handleClick);
//     };
//   }, [callback]);
// };

// const Navbar = () => {
//   const navLinks = [
//     {
//       title: "Home",
//       path: "/",
//     },
//     {
//       title: "FAQs",
//       path: "/faqs",
//     },
//     {
//       title: "Book a Space",
//       path: "https://docs.google.com/forms/d/e/1FAIpQLSdw_EDWtCu3HcQY1L12d3L-4E5Un1bEjdJ7ojyl2KvFesNWEA/viewform",
//       external: true, // Set external to true for the external link
//     },
//   ];

//   const [open, setOpen] = useState(false); // State to track the mobile menu open/close
//   const location = useLocation(); // Hook to get the current location
//   const navRef = useRef(null); // Reference to the mobile navigation menu

//   const handleNavLinkClick = (path, isExternal) => {
//     setOpen(false); // Close the mobile menu

//     // Scroll to top if the link is internal (not external)
//     if (!isExternal) {
//       scroll.scrollToTop({
//         smooth: true,
//         duration: 200,
//       });
//     }
//   };

//   const handleWindowClick = (event) => {
//     if (navRef.current && !navRef.current.contains(event.target)) {
//       setOpen(false);
//     }
//   };

//   useWindowClick(handleWindowClick); // Call the custom hook to handle window click event to close the mobile menu

//   useEffect(() => {
//     setOpen(false); // Close the mobile menu when the location changes
//   }, [location]);

//   return (
//     <div className="mb-[4rem]">
//       <nav className="bg-accent_light md:w-full fixed top-0 z-10 w-[100%] md:ml-0">
//         <Container maxW="7xl">
//           <div className="py-4 px-6 items-center justify-between md:flex md:px-10">
//             <div className="cursor-pointer">
//               <Link to="/">
//                 <Image
//                   src={Logo}
//                   alt="logo"
//                   h={{
//                     base: "60px",
//                     md: "70px",
//                     lg: "100px",
//                   }}
//                 />
//               </Link>
//             </div>
//             <div
//               onClick={() => setOpen(!open)}
//               className="text-4xl absolute right-5 top-4 cursor-pointer md:hidden"
//             >
//               {open ? <CgClose /> : <GrMenu />}
//             </div>
//             <ul
//               className={`bg-accent_light md:flex md:items-center md:justify-center md:pb-0 pb-5 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
//                 open ? "top-[5.9rem] opacity-100" : "left-[-1000px]"
//               } md:opacity-100`}
//               ref={navRef} // Assign the reference to the navigation menu
//             >
//               {navLinks.map((link, index) => (
//                 <li
//                   key={index}
//                   className="text-bold md:ml-3 lg:ml-8  sm:text-[25px] md:text-[20px] md:my-0 my-6 font-bold text-yellow-50"
//                 >
//                   {link.title === "Book a Space" ? (
//                     <a
//                       href={link.path}
//                       className="hover:text-[#a5a4a4] duration-500"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       {link.title}
//                     </a>
//                   ) : (
//                     <NavLink
//                       scrollMarginTop={"20rem"}
//                       exact
//                       to={link.path}
//                       className="hover:text-[#a5a4a4] duration-500"
//                       onClick={() =>
//                         handleNavLinkClick(link.path, link.path !== "/")
//                       }
//                     >
//                       {link.title}
//                     </NavLink>
//                   )}
//                   {location.pathname === link.path && (
//                     <div className="isActive" />
//                   )}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </Container>
//       </nav>
//     </div>
//   );
// };

// export default Navbar;
import { SiPaloaltosoftware } from "react-icons/si";
import { CgClose } from "react-icons/cg";
import { useEffect, useRef, useState } from "react";
import { Box, Container, Flex, Image } from "@chakra-ui/react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
import Logo from "../../assets/logo-white.png";
import { animateScroll as scroll } from "react-scroll";
// Custom hook to handle window click event
const useWindowClick = (callback) => {
  useEffect(() => {
    const handleClick = (event) => {
      if (callback) {
        callback();
      }
    };

    // Add event listener to the window for mousedown event
    window.addEventListener("click", handleClick);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("click", handleClick);
    };
  }, [callback]);
};

const Navbar = () => {
  const navLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "FAQs",
      path: "/faqs",
    },
    {
      title: "Book a Space",
      path: "https://docs.google.com/forms/d/e/1FAIpQLSdw_EDWtCu3HcQY1L12d3L-4E5Un1bEjdJ7ojyl2KvFesNWEA/viewform",
      external: true, // Set external to true for the external link
    },
  ];

  const [open, setOpen] = useState(false); // State to track the mobile menu open/close
  const location = useLocation(); // Hook to get the current location
  const navRef = useRef(null); // Reference to the mobile navigation menu

  const handleNavLinkClick = (path, isExternal) => {
    setOpen(false); // Close the mobile menu

    // Scroll to top if the link is internal (not external)
    if (!isExternal) {
      scroll.scrollToTop({
        smooth: true,
        duration: 200,
      });
    }
  };

  const handleWindowClick = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useWindowClick(handleWindowClick); // Call the custom hook to handle window click event to close the mobile menu

  useEffect(() => {
    setOpen(false); // Close the mobile menu when the location changes
  }, [location]);

  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top section
  };

  return (
    <div className="mb-[4rem]">
      <nav
        className=" md:w-full fixed top-0 z-10 w-[100%] md:ml-0
      border-b-2 border-[#f1eded]
      box-shadow-[0px 2px 4px rgba(0, 0, 0, 0.25)]
      bg-[#eaecee]
      "
      >
        <Container maxW="7xl">
          <div className="py-1 px-6 items-center justify-between md:flex md:px-10">
            <div className="cursor-pointer">
              <Link to="/">
                <Image
                  src={Logo}
                  alt="logo"
                  h={{
                    base: "60px",
                    md: "70px",
                    lg: "100px",
                  }}
                />
              </Link>
            </div>
            <div
              onClick={() => setOpen(!open)}
              className="text-4xl absolute right-5 top-4 cursor-pointer md:hidden"
            >
              {open ? <CgClose /> : <GrMenu />}
            </div>
            <ul
              className={`bg-[#eaecee] md:flex md:items-center md:justify-center md:pb-0 pb-5 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
                open ? "top-[4.4rem] opacity-100" : "left-[-1000px]"
              } md:opacity-100`}
              ref={navRef} // Assign the reference to the navigation menu
            >
              {navLinks.map((link, index) => (
                <li
                  key={index}
                  className="text-bold md:ml-3 lg:ml-8  sm:text-[25px] md:text-[25px] md:my-0 my-6 font-bold text-[#218bdc]"
                >
                  {link.title === "Book a Space" ? (
                    <a
                      href={link.path}
                      className="hover:text-[#a5a4a4] duration-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.title}
                    </a>
                  ) : (
                    <NavLink
                      exact
                      to={link.path}
                      className="hover:text-[#c4c1c1] duration-500"
                      onClick={scrollToTop}
                      smooth={true}
                      duration={1}
                    >
                      {link.title}
                    </NavLink>
                  )}
                  {location.pathname === link.path && (
                    <div className="isActive" />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </nav>
    </div>
  );
};

export default Navbar;
