import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin, BsTwitter, BsInstagram } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { IoTimeSharp, IoLocationSharp } from "react-icons/io5";

const Footer = () => {
  return (
    <Box
      as="footer"
      role="contentinfo"
      bg="#0e2234"
      mt={"4rem"}
      id={"contact_us"}
    >
      <Container maxW="7xl" py="12" px={{ base: "4", md: "8" }} color="#e7ecf0">
        <Flex
          gap={8}
          flexDir={{ base: "column", md: "row" }}
          justifyContent={{ base: "center", md: "space-between" }}
        >
          <Box
            width={{
              base: "100%",
              md: "40%",
            }}
          >
            <Text
              fontSize={{ base: "1rem", md: "1.2rem", lg: "1.5rem" }}
              mt={4}
              width={{
                base: "100%",
                md: "100%",
                lg: "60%",
              }}
            >
              Welcome to Keepamhere, Keep your document with zero worries
            </Text>
            <Flex mt={4} gap={4} flexDir={{ base: "column", md: "row" }}>
              <Text fontSize="lg" mt={4}>
                Follow us on:
              </Text>
              <Flex gap={2} alignItems={"center"} mt={2}>
                <Box>
                  <Link
                    to="https://www.linkedin.com/company/keepamhere"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsLinkedin size={40} className="icon" />
                  </Link>
                </Box>
                <Box>
                  <Link
                    to="https://www.instagram.com/keepamhere"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsInstagram size={40} className="icon" />
                  </Link>
                </Box>
                <Box>
                  <Link
                    to="https://www.twitter.com/keepamhere"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsTwitter size={40} className="icon" />
                  </Link>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box
            width={{
              base: "100%",
              md: "60%",
            }}
          >
            <Text
              fontSize={{
                base: "2rem",
                md: "2.5rem",
                lg: "3rem",
              }}
              textAlign={{
                base: "left",
                md: "center",
              }}
            >
              Contact us
            </Text>
            <Box
              width={"100px"}
              height={"3px"}
              bg={"white"}
              mb={{
                base: "2.5rem",
                md: "1.5rem",
              }}
              mx={{ base: "0", md: "auto" }}
            />
            <Flex
              mt={4}
              gap={4}
              flexDir={{ base: "column", md: "column", lg: "row" }}
              justifyContent={{ base: "center", md: "space-between" }}
            >
              <Flex flexDir={"column"} gap={6}>
                <Flex gap={3} alignItems={"center"}>
                  <FiPhoneCall size={28} className="icon2" />
                  <Box fontSize="16px">
                    <a href={"tel:+2349084111187"}>
                      <Text
                        fontSize={{
                          base: "1.1rem",
                          md: "1.1rem",
                          lg: "1.25rem",
                        }}
                      >
                        (234) 9084111187
                      </Text>
                    </a>
                  </Box>
                </Flex>
                <Flex gap={3} alignItems={"center"}>
                  <MdOutlineEmail size={28} className="icon2" />
                  <Box fontSize="16px">
                    <a href={"mailto:support@keepamhere.com"}>
                      <Text
                        fontSize={{
                          base: "1.1rem",
                          md: "1.1rem",
                          lg: "1.25rem",
                        }}
                      >
                        support@keepamhere.com
                      </Text>
                    </a>
                  </Box>
                </Flex>
                <Flex gap={3} alignItems={"center"}>
                  <IoTimeSharp size={28} className="icon2" />
                  <Box>
                    <Text
                      fontSize={{
                        base: "1.1rem",
                        md: "1.1rem",
                        lg: "1.25rem",
                      }}
                    >
                      Mon - Fri: 9.00 am to 16.00 pm
                    </Text>
                  </Box>
                </Flex>
              </Flex>

              {/* Address */}
              <Box>
                <Flex
                  gap={3}
                  alignItems={"center"}
                  mt={{ base: "0", md: "0", lg: "2rem" }}
                >
                  <IoLocationSharp size={28} className="icon2" />
                  <Box fontSize="16px">
                    <a
                      href={
                        "https://www.google.com/maps/dir/9.065677,7.38618/14+Wole+Olateju+Cres,+Lekki+Phase+I+106104,+Lagos/@8.015157,3.1510182,7z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x103bf4fbcfa5d62f:0x442bfdde73c812f9!2m2!1d3.4541273!2d6.4461547!3e0?entry=ttu"
                      }
                    >
                      <Text
                        fontSize={{
                          base: "1.1rem",
                          md: "1.1rem",
                          lg: "1.25rem",
                        }}
                      >
                        14 Wole olateju street, <br className="br" />
                        Off Admiralty Way, <br className="br" /> Lekki Phase 1,
                        Lagos Nigeria
                      </Text>
                    </a>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Text
          fontSize={{ base: "1rem", md: "1rem", lg: "1.2rem" }}
          textAlign={{ base: "center", md: "left" }}
          mt={{ base: "2.5rem", md: "-3rem", lg: "2.5rem" }}
        >
          © 2023 Keepamhere. All rights reserved.{" "}
        </Text>
        {/* <Flex justifyContent="center" mt="2.5rem">
          <Text>© 2021 Keepamhere. All rights reserved. </Text>
          <Text as="span" ml={2}>
            <Link to="/privacy_policy" style={{ textDecoration: "underline" }}>
              Privacy Policy
            </Link>
          </Text>
          <Text as="span" mx={2}>
            |
          </Text>
          <Text as="span">
            <Link
              to="/terms_and_conditions"
              style={{ textDecoration: "underline" }}
            >
              Terms and Conditions
            </Link>
          </Text>
        </Flex> */}
      </Container>
    </Box>
  );
};

export default Footer;
