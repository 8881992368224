export const faqsData = [
  {
    id: 1,
    question: "What storage options are available on Keepamhere?",
    answer:
      "At the moment, Keepamhere gives users the liberty to choose from three storage options. The private storage option, the shared storage option, and boxed storage option.",
  },
  {
    id: 2,
    question: "What is the Private Storage option about?",
    answer:
      "The private storage option provides you a space, usually a room where only your items are stored.",
  },
  {
    id: 3,
    question: "What is the Shared Storage option about?",
    answer:
      "The shared storage option as the name suggests and unlike the private storage, provides you a shared room space where other users have stored their items. Items stored in a shared space are labeled for proper identification.",
  },
  {
    id: 4,
    question: "What is the Boxed Storage option about?",
    answer:
      "The boxed storage options allow users to store items in boxes to a maximum box size of 60cm for the longest side of a box. All boxes are labeled for proper identification.",
  },
  {
    id: 5,
    question: "What is the maximum number of items I can store?",
    answer:
      "There is no limit to the overall number of items a user can store, however, there are restrictions within specific storage options. ",
  },
  {
    id: 6,
    question: "How often can I access my Storage?",
    answer:
      "You are free to have monthly access to your stored items. We only admit one set of clients at a time, and only if accompanied by us. This ensures your storage and contents are safe and secure. To arrange access simply call or WhatsApp +2349084111187. We are flexible and happy to meet you at a time and date that falls within - Monday to Friday 9am to 4pm. We'd really appreciate at least a 48-hour notice.",
  },
  {
    id: 7,
    question: "How do I know when my storage duration is about to expire?",
    answer: "You will receive email reminders of your storage expiration.",
  },
  {
    id: 8,
    question:
      "What happens after my storage duration expires without subscription renewal?",
    answer:
      "You will be given a 7 days grace period to renew your subscription or transfer your stored items to your preferred storage location.",
  },
  {
    id: 9,
    question:
      "What happens after my 7 days grace period expires without subscription renewal or removal of my items?",
    answer:
      "After the 7 days grace period, you will be charged a demurrage of N1,500 per day on the shared storage and N5,000 per day on the private storage in addition to your monthly storage cost and to a maximum period of 90 days.",
  },
  {
    id: 10,
    question:
      "What happens after 90 days of no subscription renewal or removal of my items?",
    answer: "We will activate the Last Resort terms of our contract",
  },
  {
    id: 11,
    question: "How do I know my items are safe?",
    answer:
      "At Keepamhere, we believe that the security of your property is a shared responsibility. We will ensure that we protect your items from theft, burglary, fire, and pest. We also expect that you do your part by insuring your valuables against unforeseen circumstances including natural disasters.",
  },
  {
    id: 12,
    question: "Is it compulsory to pay for insurance?",
    answer:
      "It is not compulsory for users to Insure stored items; however, it is highly recommended to insure your precious belongings against theft, burglary, fire, or other natural disasters like flood, etc.",
  },
  {
    id: 13,
    question: "How much is the insurance fee?",
    answer:
      "We have taken advantage of the relationships we have built with reputable insurance firms to negotiate the best industry rates for you. We have discounted with Mutual Benefits XXXXXXXXXXXXXXXXXXXXXXXXXXX",
  },
  {
    id: 14,
    question: "Are there any items that are prohibited from your storage?",
    answer: [
      "Aerosol in pressurized cans",
      "Cash or Securities Items/Documents",
      "Firearms or Ammunitions",
      "Gas canisters",
      "Hazardous or toxic products",
      "High value or extremely precious goods",
      "Illegal goods or drugs",
      "Live animals or plants",
      "Pollutants, contaminated goods or waste",
      "Perishable goods",
      "Radioactive goods",
      "Explosives",
    ],
  },
];
