import { Box, Button, Container, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Oops from "../../assets/oops.svg";
import Layout from "../layouts";

const NotFound = () => {
  return (
    <Layout>
      <Container
        py={{ base: "0.8rem", md: "1.5rem", lg: "3rem" }}
        my={{ base: "10px", md: "15px", lg: "30px" }}
        maxW="7xl"
      >
        <Box>
          <Image
            src={Oops}
            mx="auto"
            width={{ base: "80%", lg: "40%" }}
            height={{ base: "80%", lg: "40%" }}
          />

          <Flex justify="center" mt="3rem">
            <Button
              minW="250px"
              fontSize="20px"
              _hover={{ background: "orange.300" }}
              maxW="300px"
              borderRadius="full"
              py={8}
              size="lg"
              bg="red"
              color="white"
            >
              <Link to="/">Go Home</Link>
            </Button>
          </Flex>
        </Box>
      </Container>
    </Layout>
  );
};

export default NotFound;
