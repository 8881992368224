import { Box, Button, Container, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import heroImage from "../../assets/hero/store.png";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Container
      maxW={"7xl"}
      pb={"3rem"}
      mt={{ base: "2rem", md: "6rem", lg: "10rem" }}
    >
      <Flex
        //mb="2rem"
        flexDir={{ base: "column-reverse", md: "column-reverse", lg: "row" }}
        gap={{ base: "0rem", md: "1rem", lg: "2rem" }}
      >
        <Box
          w={{ base: "100%", md: "100%", lg: "50%" }}
          display={"flex"}
          flexDirection={"column"}
          //alignItems={"center"}
          mt={{ base: "0rem", md: "0rem", lg: "3rem" }}
        >
          <Text
            as={"h1"}
            fontSize={{ base: "2rem", md: "2.5rem", lg: "3rem" }}
            fontWeight={"bold"}
            lineHeight={"1.2"}
            mb={"1rem"}
            mt={{ base: "-3rem", md: "-3rem", lg: "0" }}
            textAlign={{ base: "center", md: "center", lg: "left" }}
            color={"#373737"}
          >
            Store with Confidence
          </Text>
          <Text
            fontSize={{ base: "1.2rem", md: "1.5rem", lg: "1.5rem" }}
            textAlign={{ base: "center", md: "center", lg: "left" }}
            pb={{ base: "1rem", md: "2rem", lg: "0" }}
            color={"#373737"}
          >
            Safely store your belongings in our state-of-the-art facility. Our
            secure, climate-controlled storage units are designed to protect
            your items and give you peace of mind. With flexible storage options
            and top-notch security measures, we provide a trusted storage
            solution tailored to your needs. Experience convenience,
            reliability, and exceptional service at our physical storage center.
            Book your storage unit today!
          </Text>
          <Flex justifyContent={{ base: "center", md: "center", lg: "left" }}>
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSdw_EDWtCu3HcQY1L12d3L-4E5Un1bEjdJ7ojyl2KvFesNWEA/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                bgColor="blackAlpha.900"
                _hover={{ bgColor: "blackAlpha.700" }}
                mt={{ base: "1rem", md: "1.5rem", lg: "3rem" }}
                color={"white"}
                //px="10rem"
                py="2rem"
                fontSize={"1.5rem"}
                w={"100%"}
              >
                Book a Space
              </Button>
            </Link>
          </Flex>
        </Box>
        <Box
          w={{ base: "60%", md: "100%", lg: "50%" }}
          margin={{ base: "1rem auto", md: "0 auto", lg: "0" }}
        >
          <Image
            src={heroImage}
            alt={"hero image"}
            w={{ base: "100%", md: "50%", lg: "100%" }}
            margin={{ base: "0 auto", md: "0 auto", lg: "0" }}
            //mb={{ base: "0rem", md: "2rem", lg: "0" }}
          />
        </Box>
      </Flex>
    </Container>
  );
};

export default Hero;
