import { Box, Heading, Text, Container, Image, Grid } from "@chakra-ui/react";
import { storageData } from "./data";
const Pricing = () => {
  return (
    <div>
      <Container
        maxW={"7xl"}
        my={"3rem"}
        id={"price_list"}
        scrollMarginTop={"150px"}
      >
        <Box w={"100%"}>
          <Box mb={"2rem"}>
            <Heading
              as="h2"
              fontSize={{ base: "2rem", md: "2.5rem", lg: "3rem" }}
              textAlign={"center"}
              fontWeight={"bold"}
              lineHeight={"1.2"}
              mb={"0.4rem"}
              mt={"6rem"}
            >
              Our Pricing
            </Heading>
            <Box
              width={"100px"}
              height={"3px"}
              bg={"blackAlpha.900"}
              mb={"1rem"}
              mx={"auto"}
            />
          </Box>

          <Grid
            mt={{ sm: "60px", lg: "120px" }}
            px={{ sm: "30px", lg: "10px" }}
            templateColumns={{ sm: "1fr", lg: "repeat(3, 1fr)" }}
            gap={6}
          >
            {storageData.map((item) => {
              return (
                <Box key={item.id} className="service_card_data">
                  <Box>
                    <Image
                      src={item?.image}
                      alt={item?.type}
                      margin={"0 auto"}
                      height={"100px"}
                      width={"100px"}
                      borderRadius={"50%"}
                    />
                  </Box>
                  <Heading
                    as="h2"
                    textAlign={"center"}
                    fontSize={{
                      base: "1.5rem",
                      md: "1.5rem",
                      lg: "1.5rem",
                    }}
                    fontWeight={"bold"}
                    lineHeight={"1.2"}
                    mb={"1rem"}
                    mt={"1rem"}
                  >
                    {item?.type}
                  </Heading>
                  <Box
                    width={"60px"}
                    height={"3px"}
                    bg={"#0e2234"}
                    mb={"1rem"}
                    mx={"auto"}
                  />
                  <Text
                    fontSize={{
                      base: "1.2rem",
                      md: "1.5rem",
                      lg: "1.5rem",
                    }}
                    textAlign={"center"}
                    pb={{
                      base: "2rem",
                      md: "2rem",
                      lg: "0",
                    }}
                  >
                    {item?.description}
                  </Text>
                  <Text
                    fontSize={{
                      base: "1.2rem",
                      md: "1.5rem",
                      lg: "1.2rem",
                    }}
                    fontWeight={"bold"}
                    textAlign={"center"}
                    my={2}
                    border={"1px solid #0e2234"}
                    borderRadius={"5px"}
                    py={3}
                    px={2}
                    pb={{
                      base: "2rem",
                      md: "2rem",
                      lg: "0",
                    }}
                  >
                    {item?.cost}
                  </Text>
                </Box>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Pricing;
