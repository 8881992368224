import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Container,
  Flex,
  Image,
} from "@chakra-ui/react";
import SelfStorage from "../../assets/hero/lease.svg";
import { Link } from "react-router-dom";
const StorageOwners = () => {
  return (
    <div>
      <Container
        maxW={"7xl"}
        my={{
          base: "1rem",
          md: "3rem",
          lg: "5rem",
        }}
      >
        <Flex
          mb="2rem"
          flexDir={{ base: "column", md: "column", lg: "row" }}
          gap={8}
        >
          <Box
            w={{ base: "60%", md: "100%", lg: "35%" }}
            margin={{ base: "1rem auto", md: "0 auto", lg: "0" }}
          >
            <Image
              src={SelfStorage}
              alt={"hero image"}
              w={{ base: "100%", md: "50%", lg: "100%" }}
              margin={{ base: "0 auto", md: "0 auto", lg: "0" }}
              mb={{ base: "0rem", md: "2rem", lg: "0" }}
              height={"100%"}
            />
          </Box>
          <Flex
            w={{ base: "100%", md: "100%", lg: "65%" }}
            flexDirection={"column"}
          >
            <Heading
              as="h2"
              fontSize={{ base: "1.5rem", md: "2.5rem", lg: "3rem" }}
              textAlign={{ base: "center", md: "center", lg: "left" }}
              fontWeight={"bold"}
              lineHeight={"1.2"}
              mb={"1rem"}
              color={"#373737"}
            >
              Lease Out Your Space
            </Heading>
            <Text
              mb="4"
              fontSize={{ base: "1.2rem", md: "1.5rem", lg: "1.5rem" }}
              textAlign={{ base: "center", md: "center", lg: "left" }}
              pb={{ base: "1rem", md: "2rem", lg: "0" }}
              color={"#373737"}
            >
              Have an unused space? Monetize it by leasing it out to people in
              need of storage. Join our platform and start earning income by
              sharing your available space. It's a win-win situation – you make
              money from your idle space, while others benefit from convenient
              storage options. Don't miss out on this opportunity!
            </Text>

            <Box margin={{ base: "0 auto", md: "0 auto", lg: "1rem 0" }}>
              <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSdW6KwnS9Fb9AJc81jqlzseXQbuMmz_O2DGdVDClOh4AuCQYA/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  bgColor="blackAlpha.900"
                  _hover={{ bgColor: "blackAlpha.700" }}
                  color={"white"}
                  px={{ base: "1rem", md: "3rem", lg: "4rem" }}
                  py="2rem"
                  fontWeight={"bold"}
                  fontSize={{ base: "1rem", md: "1rem", lg: "1.5rem" }}
                >
                  List Your Storage Space
                </Button>
              </Link>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </div>
  );
};

export default StorageOwners;
