import { Box } from "@chakra-ui/react";
import { BsWhatsapp } from "react-icons/bs";

const WhatsAppIcon = () => {
  return (
    <div>
      <Box>
        <a
          href="https://wa.me/message/U2ZSTY3AFBTQC1"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <BsWhatsapp fontSize={"50px"} className="whatsapp-icon" />
        </a>
      </Box>
    </div>
  );
};

export default WhatsAppIcon;
