import React from "react";
import "./App.css";
import "./index.css";
import HomePage from "./pages/home";
import NotFound from "./components/error";
import FaqPage from "./pages/faqs";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={"/"} element={<HomePage />} />
        <Route path={"/faqs"} element={<FaqPage />} />
        <Route path={"*"} element={<NotFound />} />
      </>
    )
  );
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
export default App;
